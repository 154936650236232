import React from "react";
import { useTheme } from "@material-ui/styles";
import ApexCharts from "react-apexcharts";
import moment from 'moment';

// series = [
//   {
//     name: "Metric1",
//     data: [{x: '1', y:'2'}]
//   }
// ]
// x is axis
// y is count


export default function ApexLineChart(props) {
  var theme = useTheme();

  const { tasks } = props;

  return (
    <ApexCharts
      options={themeOptions(theme)}
      series={generateData(tasks)}
      type="heatmap"
      height={350}
    />
  );
}

// ##################################################################
function generateData(tasks) {
  let timeMap = {};
  for(let day = 0; day < 7; day++) {
    let timeObj = {};
    for(let hour = 0; hour < 24; hour++) {
      timeObj[hour] = 0;
    }
    timeMap[day] = timeObj;
  }

  tasks.forEach(function (item, index) {
    const createdAt = item['createdAt']['S'];
    const createdHour = parseInt(moment(createdAt).format('H').toString(), 10);
    const createdDay = parseInt(moment(createdAt).format('e').toString(), 10);
    let count = timeMap[createdDay][createdHour] + 1;
    timeMap[createdDay][createdHour] = count;
  });


  let resultArray = [];
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  for(let day = 0; day < 7; day++) {
    const dayName = days[day];
      // length = 24
    let createdDataArray = [];
    for(let hour = 0; hour < 24; hour++) {
      const count = timeMap[day][hour];
      const dataObj = {
        x: hour,
        y: count
      }
      createdDataArray.push(dataObj);
    }
    resultArray.push({
      name: dayName,
      data: createdDataArray,
    })
  }

  return resultArray;
}

function themeOptions(theme) {
  return {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [theme.palette.primary.main],
  };
}
