import React, { useState } from "react";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Button, Grid, Paper, LinearProgress } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';


import Widget from "../../components/Widget";
import { Typography } from "../../components/Wrappers";

// API
import { formatCreatePostURL } from '../../utils/apis';

// TTS List
import { ttsList } from '../../utils/ttsList';

// styles
import useStyles from "./styles";


function TaskCreationPage(props) {
  const classes = useStyles();

  const { accessTokenState } = props;
  const [text, setText] = useState('');
  const [selectListIndex, setSelectListIndex] = useState('');
  const [languageCode, setLanguageCode] = useState('en-US');
  const [engine, setEngine] = useState('neural');
  const [voiceId, setVoiceId] = useState('Joanna');
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  const handleSelectChange = (event) => {
    setSelectListIndex(event.target.value);
    const index = event.target.value;
    if(index) {
      setLanguageCode(ttsList[index]['LanguageCode']);
      setEngine(ttsList[index]['Engine']);
      setVoiceId(ttsList[index]['VoiceId']);
    }

  }

  const handleErrorOpen = () => {
    setErrorOpen(true);
  }

  const handleErrorClose = () => {
    setErrorOpen(false);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const textOnChange = (event) => {
    setText(event.target.value);
  };

  const buttonOnClick = (event) => {
    setIsPageLoading(true);
    axios.post(formatCreatePostURL(),
      {
        data: {
            'voice': voiceId,
            'text': text,
            'languageCode': languageCode,
            'engine': engine
        }
      },
      {
        headers: {
          'Cache-Control' : 'no-cache',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-Api-Key': '*',
          'Access-Control-Allow-Credentials' : true, // Required for cookies, authorization headers with HTTPS
          Authorization: `Bearer ${accessTokenState}`,
        }
      }
      ).then(resp => {
        // Use for tracking the mounted status
        setIsPageLoading(false);
        if(resp['data']['errorMessage']) {
          handleErrorOpen();
        } else {
          handleClickOpen();
        }

    }).catch(error => {
        console.error(error);
        setIsPageLoading(false);
        setRedirect(true);
    });
  }

  if(isPageLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  }

  if(redirect) {
    return <Redirect to='/error500'/>;
  }

  return (
    <div>
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Request Submitted"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Request submitted, please check back later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Request Submitted"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You exceed your free quota, please contact us for an upgrade.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Grid container spacing={4}>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={10} md={6}>
            <Box m={4}>
              <Widget title="Instruction" disableWidgetMenu>
                <div className={classes.dashedBorder}>

                  <Typography className={classes.text} size="md">
                    We support over 25 languages and 60 voices.
                  </Typography>
                  <Typography className={classes.text} size="md">
                    Please select format language - engine - voice. Example: en-US - neural - Joana
                  </Typography>
                  <Typography className={classes.text} size="md">
                     Text-to-Speech (TTS) service uses advanced deep learning technologies to synthesize natural sounding human speech. With dozens of lifelike voices across a broad set of languages. In addition to Standard TTS voices, we offer Neural Text-to-Speech (NTTS) voices that deliver advanced improvements in speech quality through a new machine learning approach.
                  </Typography>
                </div>
              </Widget>
            </Box>
          </Grid>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={10} md={6}>
            <Box m={5}>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={20}
                fullWidth={true}
                placeholder="Start typing here"
                variant="outlined"
                onChange={textOnChange}
              />
            </Box>
          </Grid>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box m={5}>
              <Button
              fullWidth={true}
              size='large'
              color="secondary"
              variant="contained"
              onClick={buttonOnClick}
              >
                  Submit
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box m={4}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Input Language</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectListIndex}
                  onChange={handleSelectChange}
                >
                  {ttsList.map(({ LanguageCode, Engine, VoiceId }, index) => (
                    <MenuItem key={index} value={index}>{LanguageCode} - {Engine} - {VoiceId}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    </div>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
});

export default connect(mapStateToProps)(TaskCreationPage);