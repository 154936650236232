// List
// https://docs.aws.amazon.com/polly/latest/dg/API_SynthesizeSpeech.html
// https://docs.aws.amazon.com/polly/latest/dg/voicelist.html
// https://console.aws.amazon.com/polly/home/SynthesizeSpeech
export const ttsList = [
  {
    LanguageCode: 'en-US',
    Engine: 'neural',
    VoiceId: 'Joanna'
  },
  {
    LanguageCode: 'arb',
    Engine: 'standard',
    VoiceId: 'Zeina'
  },
  {
    LanguageCode: 'cmn-CN',
    Engine: 'standard',
    VoiceId: 'Zhiyu'
  },
  {
    LanguageCode: 'cy-GB',
    Engine: 'standard',
    VoiceId: 'Gwyneth'
  },
  {
    LanguageCode: 'da-DK',
    Engine: 'standard',
    VoiceId: 'Naja'
  },
  {
    LanguageCode: 'da-DK',
    Engine: 'standard',
    VoiceId: 'Mads'
  },
  {
    LanguageCode: 'de-DE',
    Engine: 'standard',
    VoiceId: 'Marlene'
  },
  {
    LanguageCode: 'de-DE',
    Engine: 'standard',
    VoiceId: 'Vicki'
  },
  {
    LanguageCode: 'de-DE',
    Engine: 'standard',
    VoiceId: 'Hans'
  },
  {
    LanguageCode: 'en-AU',
    Engine: 'standard',
    VoiceId: 'Nicole'
  },
  {
    LanguageCode: 'en-AU',
    Engine: 'neural',
    VoiceId: 'Olivia'
  },
  {
    LanguageCode: 'en-AU',
    Engine: 'standard',
    VoiceId: 'Russell'
  },
  {
    LanguageCode: 'en-GB',
    Engine: 'standard',
    VoiceId: 'Amy'
  },
  {
    LanguageCode: 'en-GB',
    Engine: 'neural',
    VoiceId: 'Amy'
  },
  {
    LanguageCode: 'en-GB',
    Engine: 'standard',
    VoiceId: 'Emma'
  },
  {
    LanguageCode: 'en-GB',
    Engine: 'neural',
    VoiceId: 'Emma'
  },
  {
    LanguageCode: 'en-GB',
    Engine: 'standard',
    VoiceId: 'Brian'
  },
  {
    LanguageCode: 'en-GB',
    Engine: 'neural',
    VoiceId: 'Brian'
  },
  {
    LanguageCode: 'en-GB-WLS',
    Engine: 'standard',
    VoiceId: 'Geraint'
  },
  {
    LanguageCode: 'en-IN',
    Engine: 'standard',
    VoiceId: 'Aditi'
  },
  {
    LanguageCode: 'en-IN',
    Engine: 'standard',
    VoiceId: 'Raveena'
  },
    {
    LanguageCode: 'en-US',
    Engine: 'standard',
    VoiceId: 'Ivy'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'neural',
    VoiceId: 'Ivy'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'standard',
    VoiceId: 'Joanna'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'neural',
    VoiceId: 'Joanna'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'standard',
    VoiceId: 'Kendra'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'neural',
    VoiceId: 'Kendra'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'standard',
    VoiceId: 'Kimberly'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'neural',
    VoiceId: 'Kimberly'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'standard',
    VoiceId: 'Salli'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'neural',
    VoiceId: 'Salli'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'standard',
    VoiceId: 'Joey'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'neural',
    VoiceId: 'Joey'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'standard',
    VoiceId: 'Justin'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'neural',
    VoiceId: 'Justin'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'neural',
    VoiceId: 'Kevin'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'standard',
    VoiceId: 'Matthew'
  },
  {
    LanguageCode: 'en-US',
    Engine: 'neural',
    VoiceId: 'Matthew'
  },
  {
    LanguageCode: 'es-ES',
    Engine: 'standard',
    VoiceId: 'Conchita'
  },
  {
    LanguageCode: 'es-ES',
    Engine: 'standard',
    VoiceId: 'Lucia'
  },
  {
    LanguageCode: 'es-ES',
    Engine: 'standard',
    VoiceId: 'Enrique'
  },
  {
    LanguageCode: 'es-MX',
    Engine: 'standard',
    VoiceId: 'Mia'
  },
  {
    LanguageCode: 'es-US',
    Engine: 'neural',
    VoiceId: 'Lupe'
  },
  {
    LanguageCode: 'es-US',
    Engine: 'standard',
    VoiceId: 'Lupe'
  },
  {
    LanguageCode: 'es-US',
    Engine: 'standard',
    VoiceId: 'Penelope'
  },
  {
    LanguageCode: 'es-US',
    Engine: 'standard',
    VoiceId: 'Miguel'
  },
  {
    LanguageCode: 'fr-CA',
    Engine: 'standard',
    VoiceId: 'Chantal'
  },
  {
    LanguageCode: 'fr-FR',
    Engine: 'standard',
    VoiceId: 'Celine'
  },
  {
    LanguageCode: 'fr-FR',
    Engine: 'standard',
    VoiceId: 'Lea'
  },
  {
    LanguageCode: 'fr-FR',
    Engine: 'standard',
    VoiceId: 'Mathieu'
  },
  {
    LanguageCode: 'is-IS',
    Engine: 'standard',
    VoiceId: 'Dora'
  },
  {
    LanguageCode: 'is-IS',
    Engine: 'standard',
    VoiceId: 'Karl'
  },
  {
    LanguageCode: 'it-IT',
    Engine: 'standard',
    VoiceId: 'Carla'
  },
  {
    LanguageCode: 'it-IT',
    Engine: 'standard',
    VoiceId: 'Bianca'
  },
  {
    LanguageCode: 'it-IT',
    Engine: 'standard',
    VoiceId: 'Giorgio'
  },
  {
    LanguageCode: 'ja-JP',
    Engine: 'standard',
    VoiceId: 'Mizuki'
  },
  {
    LanguageCode: 'ja-JP',
    Engine: 'standard',
    VoiceId: 'Takumi'
  },
  {
    LanguageCode: 'hi-IN',
    Engine: 'standard',
    VoiceId: 'Aditi'
  },
  {
    LanguageCode: 'ko-KR',
    Engine: 'standard',
    VoiceId: 'Seoyeon'
  },
  {
    LanguageCode: 'nb-NO',
    Engine: 'standard',
    VoiceId: 'Liv'
  },
  {
    LanguageCode: 'nl-NL',
    Engine: 'standard',
    VoiceId: 'Lotte'
  },
  {
    LanguageCode: 'nl-NL',
    Engine: 'standard',
    VoiceId: 'Ruben'
  },
  {
    LanguageCode: 'pl-PL',
    Engine: 'standard',
    VoiceId: 'Ewa'
  },
  {
    LanguageCode: 'pl-PL',
    Engine: 'standard',
    VoiceId: 'Maja'
  },
  {
    LanguageCode: 'pl-PL',
    Engine: 'standard',
    VoiceId: 'Jacek'
  },
  {
    LanguageCode: 'pl-PL',
    Engine: 'standard',
    VoiceId: 'Jan'
  },
  {
    LanguageCode: 'pt-BR',
    Engine: 'standard',
    VoiceId: 'Camila'
  },
  {
    LanguageCode: 'pt-BR',
    Engine: 'neural',
    VoiceId: 'Camila'
  },
  {
    LanguageCode: 'pt-BR',
    Engine: 'standard',
    VoiceId: 'Vitoria'
  },
  {
    LanguageCode: 'pt-BR',
    Engine: 'standard',
    VoiceId: 'Ricardo'
  },
    {
    LanguageCode: 'pt-PT',
    Engine: 'standard',
    VoiceId: 'Ines'
  },
  {
    LanguageCode: 'pt-PT',
    Engine: 'standard',
    VoiceId: 'Cristiano'
  },
  {
    LanguageCode: 'ro-RO',
    Engine: 'standard',
    VoiceId: 'Carmen'
  },
  {
    LanguageCode: 'ru-RU',
    Engine: 'standard',
    VoiceId: 'Tatyana'
  },
  {
    LanguageCode: 'ru-RU',
    Engine: 'standard',
    VoiceId: 'Maxim'
  },
  {
    LanguageCode: 'sv-SE',
    Engine: 'standard',
    VoiceId: 'Astrid'
  },
  {
    LanguageCode: 'tr-TR',
    Engine: 'standard',
    VoiceId: 'Filiz'
  }
]