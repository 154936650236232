// DOMAIN
export const LOCAL_HOST = "http://localhost:3000/"; // Used for dev
export const DOMAIN = "https://www.alanai.co/" //  Use for marketing page, log out redirect
export const SUB_DOMAIN = "https://app.alanai.co/" // Use for real app, log in redirect

// Auth0
export const AUTH0_CLIENT_ID = 'IDDHVwkgCRZ95LB7na76AR05VupMo7dP';
export const AUTH0_CLIENT_SECRET = '6rCrHA13wfAE9bwE1tpfWXRH4PbsXCnAKRm4DSRAP6VKuysH_pM8sdFxOalKOL-P';
export const AUTH0_DOMAIN = 'dev-oyqrjd2a.us.auth0.com';
export const AUTH0_AUDIENCE = 'https://auth0-jwt-authorizer';
export const RESET_PS_URI = `https://${AUTH0_DOMAIN}/dbconnections/change_password`;
export const AUTH0_LOGIN_REDIRECT_URI = `${SUB_DOMAIN}`; // this needs to match auth0.com
export const AUTH0_LOGOUT_REDIRECT_URI = `${DOMAIN}`; // this needs to match auth0.com

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

// Routes
export const INTRO = '/';
export const DASHBOARD = '/dashboard';
export const TASKS = '/tasks';
export const CREATE = '/create';
export const USAGE = '/usage';
export const RECENT = '/recent';
export const STARRED = '/starred';
export const UPGRADE = '/upgrade';
export const SUPPORT = '/support';
export const PROFILE = '/profile';
export const ERROR_500 = '/error500';
export const CATCH_ALL = '/*';
